import dynamic from 'next/dynamic';
import * as yup from 'yup';

const PersonalDetails = dynamic(() => import('./PersonalDetails'), { ssr: false });

export default function PersonalDetailsContainer(props) {
  return <PersonalDetails {...props} validationSchema={VALIDATION_SCHEMA} />;
}

PersonalDetailsContainer.validateStep = function (data) {
  return VALIDATION_SCHEMA.isValidSync(data.request);
};

const VALIDATION_SCHEMA = yup.object().shape({
  existingCustomerOption: yup.string(),
  name: yup.string()
    .min(1)
    .max(40, 'nameMaxHelperText')
    .required('nameHelperText'),
  phone: yup.string()
    .min(5, 'phoneNumberMinHelperText')
    .max(30, 'phoneNumberMaxHelperText')
    .required('phoneNumberYupHelperText'),
  licensePlate: yup.string()
    .min(3)
    .max(10)
    .required('licensePlateHelperText'),
  vin: yup.string()
    .when('existingCustomerOption', {
      is: 'new',
      then: yup
        .string()
        .required('vehicleIdentificationNumberHelperText'),
    }),
  dateOfFirstRegistration: yup.date()
    .nullable()
    .default(null)
    .when('existingCustomerOption', {
      is: 'new',
      then: yup
        .date()
        .required('dateOfFirstRegistrationHelperText'),
    }),
});
