import * as yup from 'yup';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  Typography, useTheme,
} from '@mui/material';
import {
  FiClock,
  FiExternalLink,
  FiMail,
  FiMapPin,
  FiPhone,
} from 'react-icons/fi';
import { useState } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import defaults from 'lodash/defaults';
import { useTranslation } from 'react-i18next';
import { MediaElement, TypographyElement, useContentElement } from '@plugins/next-cms-core';
import { trackEvent } from '@components/organisms/Tracking';
import Slider from '@components/atoms/Slider';
import Link from '@components/atoms/Link';
import Container from '@components/atoms/Container';
import Image from '@components/atoms/Image';

export default function BranchBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const { elementData } = useContentElement(
    data,
    BranchBlock.dataSchema,
  );

  let title = null;

  if (elementData.title) {
    title = omitBy(elementData.title, isNull);
    defaults(title, {
      semanticVariant: 'h3',
      displayVariant: 'h3',
      textAlign: 'center',
    });
  }

  return (
    <Box
      sx={{
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        '& .mediaContainer': {
          transform: 'scale(1.2)',
        },
        '& .mediaLabel': {
          maxWidth: '70%',
        },
      }}
    >
      <Container>
        {title && (
          <Box mb={5}>
            <TypographyElement data={title} />
          </Box>
        )}
        <Grid2
          alignItems="center"
          container
          spacing={3}
        >
          <Grid2 size={{ md: 6, xs: 12 }}>
            {elementData.branch.data ? (
              <Branches
                branch={elementData.branch.data}
              />
            ) : (
              <Alert severity="warning">
                No branch is selected
              </Alert>
            )}
          </Grid2>
          <Grid2 size={{ md: 6, xs: 12 }}>
            <ImageBlock data={elementData} theme={theme} />
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}

function Branches(props) {
  const {
    branch,
  } = props;
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent>
        {branch.attributes.addressStreet && (
          <Box>
            <Grid2
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
            >
              <Grid2 size={{ sm: 'auto', xs: 12 }} sx={{ flex: 1 }}>
                <Box alignItems="center" display="flex">
                  <Box mr={1}>
                    <FiMapPin />
                  </Box>
                  <Typography component="div" variant="body2">
                    <strong>{branch.attributes.addressStreet}</strong>
                    {', '}
                    {branch.attributes.addressZipcode}
                    {' '}
                    {branch.attributes.addressCity}
                  </Typography>
                </Box>
              </Grid2>
              <Grid2 size={{ sm: 'auto', xs: 12 }}>
                <Button
                  color="primary"
                  fullWidth
                  onClick={openRoute(
                    `${branch.attributes.addressStreet}, ${branch.attributes.addressZipcode} ${branch.attributes.addressCity}, ${branch.attributes.addressCountry}`,
                  )}
                  startIcon={<FiExternalLink />}
                  variant="outlined"
                >
                  {t('components.contentTypes.BranchBlock.directions')}
                </Button>
              </Grid2>
            </Grid2>
          </Box>
        )}
        <Box mt={2}>
          <Typography variant="h6">
            {t('components.contentTypes.BranchBlock.workshop')}
          </Typography>
          <BranchDepartment
            businessHours={branch.attributes.businessHoursWorkshop}
            email={branch.attributes.workshopEmail}
            phone={branch.attributes.workshopPhone}
          />
        </Box>
        <Box mt={3}>
          <Typography variant="h6">
            {t('components.contentTypes.BranchBlock.sale')}
          </Typography>
          <BranchDepartment
            businessHours={branch.attributes.businessHoursSales}
            email={branch.attributes.primaryEmail}
            phone={branch.attributes.salesPhone}
          />
        </Box>
        <Box mt={3}>
          <Link href={branch.attributes.appointmentUrl} legacyBehavior>
            <Button
              color="primary"
              component="a"
              variant="contained"
            >
              {t('components.contentTypes.BranchBlock.onlineScheduler')}
            </Button>
          </Link>
        </Box>
        <Box
          mt={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Grid2 container spacing={4}>
            <Grid2 size={{ xs: 1 }} />
            <Grid2 size={{ xs: 2 }}>
              <Image
                alt="Hyundai"
                isFluid
                src="/images/car-brands/hyundai-motor-company-logo-2.svg"
                styles={{ width: 195 }}
              />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Image
                alt="MG Motor"
                isFluid
                src="/images/car-brands/mgmotors-logo.svg"
                styles={{ width: 145 }}
              />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Image
                alt="Mitsubishi"
                isFluid
                src="/images/car-brands/mitsubishi-logo.svg"
                styles={{ width: 145 }}
              />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Image
                alt="FUSO"
                isFluid
                src="/images/car-brands/mitsubishi-fuso-logo.svg"
                styles={{ width: 145 }}
              />
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Image
                alt="Maxus"
                isFluid
                src="/images/car-brands/maxus-logo.png"
                styles={{ width: 145 }}
              />
            </Grid2>
          </Grid2>
        </Box>
      </CardContent>
    </Card>
  );
}

function BranchDepartment(props) {
  const { businessHours, phone, email } = props;
  const trimTime = (time) => time.substring(0, 5);
  const { t } = useTranslation();

  return (
    <div>
      {phone && (
        <Box>
          <Link href={`tel:${phone}`} legacyBehavior>
            <Button
              color="primary"
              component="a"
              onClick={() => {
                trackEvent('Contact');
                trackEvent('ContactCustom', { channel: 'Phone', origin: 'Branch' });
              }}
              size="small"
              startIcon={<FiPhone />}
              variant="text"
            >
              {phone}
            </Button>
          </Link>
        </Box>
      )}
      {email && (
        <Box>
          <Link href={`mailto:${email}`} legacyBehavior>
            <Button
              color="primary"
              component="a"
              onClick={() => {
                trackEvent('Contact');
                trackEvent('ContactCustom', { channel: 'Email', origin: 'Branch' });
              }}
              size="small"
              startIcon={<FiMail />}
              variant="text"
            >
              {email}
            </Button>
          </Link>
        </Box>
      )}
      {businessHours.length > 0 && (
        <Box mt={1} sx={{ maxWidth: 300 }}>
          <Typography gutterBottom variant="subtitle2">
            Öffnungszeiten
          </Typography>
          <Box display="flex">
            <Box mr={2} mt={0.5}>
              <FiClock className="icon" />
            </Box>
            <Box flex={1}>
              {businessHours.map((item) => (
                <Grid2 key={item.id} container>
                  <Grid2 size={{ xs: 4 }}>
                    {`${DAY_MAPPING[item.dayRangeStart]}.`}
                    {item.dayRangeEnd && ` - ${DAY_MAPPING[item.dayRangeEnd]}.`}
                  </Grid2>
                  <Grid2 size={{ xs: 8 }}>
                    {trimTime(item.dayTimeStart)}
                    {item.dayTimeEnd && ` - ${trimTime(item.dayTimeEnd)}`}
                    {' '}
                    {t('components.contentTypes.BranchBlock.clock')}
                  </Grid2>
                </Grid2>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}

function ImageBlock(props) {
  const { data, theme } = props;
  const { media } = data;
  const [swiper, setSwiper] = useState();

  if (media.length === 0) {
    return null;
  }

  if (media.length === 1) {
    const firstMedia = media[0];

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <MediaElement data={firstMedia} />
        {firstMedia.caption && (
          <Box
            sx={{
              flex: 1,
              marginTop: theme.spacing(0.5),
            }}
          >
            <Typography variant="caption">
              {firstMedia.caption}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Slider
      autoHeight
      autoplay={{
        delay: 5000,
      }}
      isButtonsVisible={false}
      items={media}
      setSwiper={setSwiper}
      SlideComponent={BranchBlockSlideItem}
      slideComponentProps={{
        theme,
      }}
      sliderStyles={{
        paddingBottom: theme.spacing(3),
        marginTop: -7,
        paddingTop: 20,
      }}
      swiper={swiper}
    />
  );
}

function BranchBlockSlideItem(props) {
  const { item, theme } = props;
  return (
    <>
      <MediaElement data={item} />
      <Box
        sx={{
          flex: 1,
          marginTop: theme.spacing(0.5),
        }}
      >
        <Typography variant="caption">
          {item.caption}
        </Typography>
      </Box>
    </>
  );
}

const DAY_MAPPING = {
  monday: 'Mo',
  tuesday: 'Di',
  wednesday: 'Mi',
  thursday: 'Do',
  friday: 'Fr',
  saturday: 'Sa',
  sunday: 'So',
};

BranchBlock.typeName = 'ComponentContentBranch'; // Strapi element type
BranchBlock.propTypes = {
  data: PropTypes.shape({
    title: TypographyElement.propTypes,
    media: yup.array(MediaElement.dataSchema).nullable(),
  }).isRequired,
};
BranchBlock.dataSchema = yup.object().shape({
  id: yup.number().required(),
  title: TypographyElement.dataSchema.nullable(),
  media: yup.array(MediaElement.dataSchema).nullable(),

});
BranchBlock.graphQlSchema = `
... on ${BranchBlock.typeName} {
  id
  title {
    ${TypographyElement.graphQlSchema}
  }
  media {
    ${MediaElement.graphQlSchema}
  }
  branch {
    data {
      id
      attributes {
        addressCity
        addressCountry
        addressStreet
        addressZipcode
        appointmentUrl
        branchId
        geocodeLat
        geocodeLng
        name
        pageUrl
        primaryEmail
        primaryPhone
        salesPhone
        workshopEmail
        workshopPhone
        businessHoursSales {
          id
          dayRangeEnd
          dayRangeStart
          dayTimeEnd
          dayTimeStart
        }
        businessHoursWorkshop {
          id
          dayRangeEnd
          dayRangeStart
          dayTimeEnd
          dayTimeStart
        }
      }
    }
  }
}
`;

function openRoute(destination) {
  return () => {
    const isAppleUser = /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent);
    let url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(destination)}`;

    if (isAppleUser) {
      url = `http://maps.apple.com/?q=${encodeURIComponent(destination)}`;
    }

    const win = window.open(url, '_blank');
    win.focus();
  };
}
