import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FiCalendar, FiX } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { useContentElement } from '@plugins/next-cms-core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { collapsedHeightDesktop, collapsedHeightMobile } from '@components/organisms/Header';
import Container from '@components/atoms/Container';
import Link from '@components/atoms/Link';
import Hidden from '@components/atoms/Hidden';
import ModelRequestForm from '../../forms/ModelRequestForm';
import { zIndices } from '../../theme';

export default function ModelRequestFormBlock(props) {
  const { data } = props;
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isFormVisible, setFormVisible] = useState(false);
  const { elementData } = useContentElement(
    data,
    ModelRequestFormBlock.dataSchema,
  );

  const stickThresholdTop = parseInt((isMobile
    ? collapsedHeightMobile
    : collapsedHeightDesktop
  ).replace('px', ''), 10) - 2;
  const {
    modelRequestFormTitle: title,
    modelRequestFormSubtitle: subtitle,
    modelRequestFormPreEnteredMessage: preEnteredMessage,
    modelRequestFormButtonInfoText: buttonInfoText,
    modelRequestFormButtonLabel: buttonLabel,
    formColors,
    formEquipmentLines,
    isCarSubscriptionAvailable,
  } = elementData;

  const handleToggleForm = () => {
    setFormVisible(!isFormVisible);
  };

  useEffect(() => {
    const handleHashChange = (url) => {
      if (url.includes('#') && url.split('#')[1] === 'anfragen') {
        setFormVisible(true);
      }
    };

    router.events.on('hashChangeStart', handleHashChange);
    handleHashChange(router.asPath);
    return () => {
      router.events.off('hashChangeStart', handleHashChange);
    };
  }, [router, setFormVisible]);

  return (
    <Box
      sx={{
        top: stickThresholdTop,
        backgroundColor: theme.palette.primary.main,
        position: 'sticky',
        zIndex: zIndices.modelRequestForm,
      }}
    >
      <Container
        sx={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          overflowX: 'auto',
        }}
      >
        <Grid2 container spacing={3} sx={{ alignItems: 'center' }}>
          <Hidden dir="down" size="md">
            <Grid2 size={{ md: 6, xs: 12 }}>
              <Typography
                color="secondary"
                component="span"
                sx={{
                  fontWeight: '900',
                }}
                variant="h5"
              >
                {title}
              </Typography>
              {' '}
              <Typography
                color="secondary"
                component="span"
              >
                {subtitle}
              </Typography>
            </Grid2>
          </Hidden>
          <Grid2 size={{ md: 6, xs: 12 }}>
            <Box alignItems="center" display="flex" justifyContent="flex-end">
              <Box mr={3}>
                <Typography
                  color="secondary"
                  component="span"
                >
                  {buttonInfoText || t('components.contentTypes.ModelRequestFormBlock.buttonInfoText')}
                </Typography>
              </Box>
              <Button
                color="secondary"
                onClick={handleToggleForm}
                size="small"
                sx={{
                  minWidth: 140,
                }}
                variant="contained"
              >
                {buttonLabel || t('components.contentTypes.ModelRequestFormBlock.consultas')}
              </Button>
            </Box>
          </Grid2>
        </Grid2>
        <Dialog
          fullWidth
          maxWidth="lg"
          onClose={handleToggleForm}
          open={isFormVisible}
          scroll="body"
        >
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <DialogTitle>
              {buttonLabel || t('components.contentTypes.ModelRequestFormBlock.buttonLabel')}
            </DialogTitle>
            <Box pr={1}>
              <IconButton onClick={handleToggleForm}>
                <FiX />
              </IconButton>
            </Box>
          </Box>
          <DialogContent>
            {!buttonLabel && (
              <>
                <Box>
                  <Link href="https://www.etermin.net/schimmel" legacyBehavior>
                    <Button
                      color="primary"
                      component="a"
                      rel="noopener nofollow"
                      startIcon={
                        <FiCalendar />
                      }
                      target="_blank"
                      variant="contained"
                    >
                      {t('components.contentTypes.ModelRequestFormBlock.arrangeTestDriveNow')}
                    </Button>
                  </Link>
                </Box>
                <Box mb={3} mt={5}>
                  <Divider />
                </Box>
              </>
            )}
            <Box mb={3}>
              {!buttonLabel && (
                <Typography variant="h6">
                  {t('components.contentTypes.ModelRequestFormBlock.individualRequest')}
                </Typography>
              )}
              <ModelRequestForm
                formFields={{
                  colors: formColors,
                  equipmentLines: formEquipmentLines,
                }}
                message={preEnteredMessage}
                vehicle={{
                  attributes: {
                    isCarSubscription: isCarSubscriptionAvailable,
                  },
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
}

ModelRequestFormBlock.typeName = 'ComponentContentModelRequestForm'; // Strapi element type
ModelRequestFormBlock.propTypes = {
  data: PropTypes.shape({
    modelRequestFormTitle: PropTypes.string,
    modelRequestFormSubtitle: PropTypes.string,
    modelRequestFormPreEnteredMessage: PropTypes.string,
  }).isRequired,
};
ModelRequestFormBlock.dataSchema = yup.object()
  .shape({
    modelRequestFormTitle: yup.string()
      .nullable(),
    modelRequestFormSubtitle: yup.string()
      .nullable(),
    modelRequestFormPreEnteredMessage: yup.string()
      .nullable(),
  });
ModelRequestFormBlock.graphQlSchema = `
... on ${ModelRequestFormBlock.typeName} {
  id
  modelRequestFormTitle: title
  modelRequestFormSubtitle: subtitle
  modelRequestFormPreEnteredMessage: preEnteredMessage
  modelRequestFormButtonInfoText: buttonInfoText
  modelRequestFormButtonLabel: buttonLabel
  formColors
  formEquipmentLines
  isCarSubscriptionAvailable
}
`;
