import * as yup from 'yup';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useContentElement,
  TypographyElement,
} from '@plugins/next-cms-core';
import defaults from 'lodash/defaults';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import CarSearchFilterBar from '../organisms/CarSearchFilterBar';
import Container from '../atoms/Container';

export default function CarSearchFilterBlock(props) {
  const { data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { elementData } = useContentElement(
    data,
    CarSearchFilterBlock.dataSchema,
  );

  const {
    brand,
    isTranslatedY,
    branch,
  } = elementData;

  const title = omitBy(elementData.title, isNull);
  defaults(title, {
    semanticVariant: 'h3',
    displayVariant: 'h3',
  });

  const subtitle = omitBy(elementData.subtitle, isNull);
  defaults(subtitle, {
    semanticVariant: 'h4',
    displayVariant: 'subtitle1',
  });

  return (
    <div>
      <Container>
        <CarSearchFilterBar
          isMobile={isMobile}
          preSelectedBranch={branch?.data?.id}
          preSelectedBrand={brand}
          subtitle={subtitle}
          title={title}
          translateY={isMobile ? false : isTranslatedY}
        />
      </Container>
    </div>
  );
}

CarSearchFilterBlock.typeName = 'ComponentContentCarSearchFilter'; // Strapi element type
CarSearchFilterBlock.propTypes = {
  data: PropTypes.shape({
    isTranslatedY: PropTypes.bool,
  }).isRequired,
};
CarSearchFilterBlock.dataSchema = yup.object().shape({
  isTranslatedY: yup.bool().nullable(),
});
CarSearchFilterBlock.graphQlSchema = `
... on ${CarSearchFilterBlock.typeName} {
  id
  brand
  isTranslatedY
  branch {
    data {
      id
    }
  }
  title {
    ${TypographyElement.graphQlSchema}
  }
  subtitle {
    ${TypographyElement.graphQlSchema}
  }
}
`;
