import {
  Box, Button, CardActionArea, Chip, Grid2, Paper, Typography, useTheme,
} from '@mui/material';
import { DateTime } from 'luxon';
import { FiArrowRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import Link from '@components/atoms/Link';
import { MediaElement, RibbonElement, TypographyElement } from '@plugins/next-cms-core';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const BREAKPOINT = 'md';

const BlogPreviewMedia = styled(MediaElement)(({ theme, useMobileVariant }) => ({
  flex: 1,
  aspectRatio: '16 / 9',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  ...(!useMobileVariant ? {
    [theme.breakpoints.up(BREAKPOINT)]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  } : {}),
}));

export default function BlogPreviewItem(props) {
  const {
    item,
    useMobileVariant,
  } = props;
  const theme = useTheme();
  const router = useRouter();
  const { t } = useTranslation();
  const isBlogItem = item.attributes.pageType === 'blogItem';

  const { url } = item.attributes;
  const date = DateTime.fromISO(item.attributes.publishDate ?? item.attributes.publishedAt);
  const isExpired = item.attributes.expirationDate
    && DateTime.fromISO(item.attributes.expirationDate) < DateTime.now();

  const handleGoToBlogItem = (path) => {
    router.push(path);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        position: 'relative',
        '& *': {
          textDecoration: 'none !important',
        },
      }}
    >
      <CardActionArea onClick={() => handleGoToBlogItem(url)}>
        <Grid2
          container
          sx={{
            alignItems: 'stretch',
          }}
        >
          {item.attributes.blogPreviewMedia && (
            <Grid2
              size={useMobileVariant ? {
                xs: 12,
              } : {
                [BREAKPOINT]: 6,
                xs: 12,
              }}
              sx={{
                position: 'relative',
                marginBottom: theme.spacing(2),
                ...(!useMobileVariant ? {
                  [theme.breakpoints.up(BREAKPOINT)]: {
                    marginRight: theme.spacing(2),
                    marginBottom: 0,
                  },
                } : {}),
              }}
            >
              <BlogPreviewMedia
                data={item.attributes.blogPreviewMedia}
                format="large"
                isFluid
                useMobileVariant={useMobileVariant}
              />
              {item.attributes.blogPreviewMediaRibbon && (
                <RibbonElement data={item.attributes.blogPreviewMediaRibbon} />
              )}
              {item.attributes.expirationDate
                && (!isExpired && !item.attributes.isPromotionExtended ? null : (
                  <Box
                    sx={{
                      top: 0,
                      right: 0,
                      width: 0,
                      height: 0,
                      position: 'absolute',
                      borderTop: `125px solid ${theme.palette.primary.main}`,
                      borderLeft: '125px solid transparent',
                      borderTopLeftRadius: theme.shape.borderRadius,
                      pointerEvents: 'none',
                      ...(isExpired && {
                        left: 0,
                        right: 'unset',
                        borderLeft: 'unset',
                        borderRight: '125px solid transparent',
                      }),
                    }}
                  >
                    <Typography
                      sx={{
                        position: 'absolute',
                        color: 'white',
                        textAlign: 'center',
                        lineHeight: '1.1em',
                        fontWeight: '900',
                        transform: 'rotate(45deg) translateY(-15px) translateX(-128px)',
                        ...(isExpired && {
                          transform: 'rotate(-45deg) translateY(-70px) translateX(70px)',
                        }),
                      }}
                      variant="subtitle1"
                    >
                      {!isExpired && item.attributes.isPromotionExtended
                        && t('components.molecules.BlogPreviewItem.actionExtended')}
                      {isExpired && t('components.molecules.BlogPreviewItem.actionExpired')}
                    </Typography>
                  </Box>
                ))}
            </Grid2>
          )}
          <Grid2
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              padding: theme.spacing(3),
            }}
          >
            <Box alignItems="center" display="flex">
              {!isBlogItem && (
                <Chip
                  color="primary"
                  label={t('components.molecules.BlogPreviewItem.action')}
                  size="small"
                  sx={{ mr: 1 }}
                />
              )}
              <Typography color="primary" variant="body2">
                {date.toFormat('dd.MM.yyyy')}
              </Typography>
            </Box>
            <Typography component="h4" variant="h5">
              {item.attributes.name}
            </Typography>
            {item.attributes.blogPreviewText && (
              <TypographyElement
                data={{
                  ...item.attributes.blogPreviewText,
                  value: `${item.attributes.blogPreviewText.value.length > 250
                    ? `${item.attributes.blogPreviewText.value.substring(0, 250)}...`
                    : item.attributes.blogPreviewText.value}`,
                }}
                sx={{ my: 3 }}
              />
            )}
            <Link href={url} legacyBehavior>
              <Button
                color="primary"
                component="a"
                endIcon={<FiArrowRight />}
                variant="outlined"
              >
                {t(`components.molecules.BlogPreviewItem.${isBlogItem ? 'readMore' : 'learnMore'}`)}
              </Button>
            </Link>
          </Grid2>
        </Grid2>
      </CardActionArea>
    </Paper>
  );
}

BlogPreviewItem.propTypes = {
  // eslint-disable-next-line react/require-default-props
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      pageType: PropTypes.string.isRequired,
      publishedAt: PropTypes.string.isRequired,
      publishDate: PropTypes.string,
      blogPreviewText: PropTypes.shape(TypographyElement.propTypes),
      blogPreviewMedia: PropTypes.shape(MediaElement.propTypes),
    }),
  }),
};

BlogPreviewItem.graphQlSchema = `
  id
  attributes {
    name
    url
    slug
    pageType
    publishedAt
    publishDate
    isPinnedToTop
    expirationDate
    isPromotionExtended
    tags {
      data {
        id
      }
    }
    blogPreviewText {
      ${TypographyElement.graphQlSchema}
    }
    blogPreviewMedia {
      ${MediaElement.graphQlSchema}
    }
    blogPreviewMediaRibbon {
      ${RibbonElement.graphQlSchema}
    }
  }
`;
