import { dateTimeField } from '@lib/form';
import PropTypes from 'prop-types';
import DatePickerProvider from '@components/atoms/DatePickerProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function DateTimeField(props) {
  const { type } = props;

  let Component = null;

  switch (type) {
    case 'time':
      Component = TimeField;
      break;
    case 'date':
      Component = DateField;
      break;
    case 'datetime':
      Component = DateAndTimeField;
      break;
    default:
      Component = null;
  }

  if (Component) {
    return (
      <DatePickerProvider>
        <Component {...props} />
      </DatePickerProvider>
    );
  }

  throw new Error(`Type '${type}' not supported.`);
}

DateTimeField.propTypes = {
  type: PropTypes.oneOf(['date', 'datetime', 'time']).isRequired,
};

function TimeField(props) {
  const {
    disabled,
    value = '',
    error,
    touched,
    label = '',
    handleBlur,
    handleChange,
    readOnly,
    name,
  } = props;

  return (
    <TimePicker
      disabled={disabled}
      label={label}
      name={name}
      onChange={handleChange}
      slotProps={{
        textField: {
          fullWidth: true,
          size: 'small',
          error: error && touched,
          helperText: error,
          shrink: true,
          readOnly,
          onBlur: handleBlur,
        },
      }}
      value={value}
    />
  );
}

TimeField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

function DateField(props) {
  const {
    disabled,
    value = '',
    error,
    touched,
    label = '',
    handleBlur,
    name,
    handleChange,
    helperText = '',
    readOnly,
  } = props;

  return (
    <DatePicker
      disabled={disabled}
      label={label}
      name={name}
      onChange={handleChange}
      slotProps={{
        textField: {
          fullWidth: true,
          error: error && touched,
          helperText: error || helperText,
          shrink: true,
          readOnly,
          onBlur: handleBlur,
          size: 'small',
        },
      }}
      value={value}
    />

  );
}

DateField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

function DateAndTimeField(props) {
  const {
    disabled,
    value = '',
    error,
    touched,
    label,
    name,
    handleBlur,
    handleChange,
    readOnly,
  } = props;

  return (
    <DateTimePicker
      disabled={disabled}
      label={label}
      name={name}
      onChange={handleChange}
      slotProps={{
        textField: {
          error: error && touched,
          fullWidth: true,
          helperText: error,
          shrink: true,
          readOnly,
          onBlur: handleBlur,
          size: 'small',
        },
      }}
      value={value}
    />
  );
}

DateAndTimeField.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  label: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

DateTimeField.getDefaultValue = dateTimeField.getDefaultValue;
DateTimeField.serializeValue = dateTimeField.serializeValue;
DateTimeField.deserializeValue = dateTimeField.deserializeValue;
DateTimeField.getDisplayValue = dateTimeField.getDisplayValue;
DateTimeField.validationSchema = dateTimeField.validationSchema;
DateTimeField.graphQlSchema = `
typeDateTime {
  id
  type
}
`;
