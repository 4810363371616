import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n from '@/i18n';

export default function DatePickerProvider({ children }) {
  return (
    <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
      {children}
    </LocalizationProvider>
  );
}
